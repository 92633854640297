import type { ServicesAndLanguagesRegistry } from ".";
import type { Language } from "./Language";

export class LanguageCollection extends Map<string, Language> {
  // This should be an actual constructor, but due to https://github.com/microsoft/TypeScript/issues/10853 Map cannot be extended.
  static construct(languages?: Array<Language>) {
    const ret = new LanguageCollection();
    if (languages) {
      for (const language of languages) {
        ret.set(language.toString(), language);
      }
    }
    return ret;
  }

  // constructor(languages?: Array<Language>) {
  //   super(); // complains that `super` must be called using `new`
  //   new super(); // complains that `Map<string, Language>` has no constructor signatures (and also complains if no generic type info is given)
  //   if (languages) {
  //     for (const language of languages) {
  //       this.set(language.toString(), language);
  //     }
  //   }
  // }

  static intersection(
    firstSource: LanguageCollection,
    ...otherSources: Array<LanguageCollection>
  ) {
    const result = LanguageCollection.construct();
    for (const [tag, language] of firstSource.entries()) {
      if (
        otherSources.every((source) => Array.from(source.keys()).includes(tag))
      ) {
        result.set(tag, language);
      }
    }
    return result;
  }

  static union(...sources: Array<LanguageCollection>) {
    const result = LanguageCollection.construct();
    for (const source of sources) {
      for (const [tag, language] of source.entries()) {
        result.set(tag, language);
      }
    }
    return result;
  }

  getPrimaryLanguages(): LanguageCollection {
    // TODO check if this already has only primaries
    const result = LanguageCollection.construct();
    for (const [tag, language] of this.entries()) {
      if (language.isPrimary()) {
        result.set(tag, language);
      }
    }
    return result;
  }

  getDropdownOptions(placeholderMissingLanguageTranslation: string, lang: ServicesAndLanguagesRegistry, displayLanguage: Language): Array<Record<string, any>> {
    // TODO function has been adjusted from previous pure-string handling to working with Language instances, but needs more cleanup
    const ret = new Array<Record<string, any>>();
    let keys = Array.from(this.keys());

    // remove german and english from the list
    const remaining = keys
      .filter((key) => !key.startsWith("de") && !key.startsWith("en"))
      .sort();
    const removed = keys
      .filter((key) => key.startsWith("de") || key.startsWith("en"))
      .sort();

    keys = remaining;

    // add german and english to the start
    keys.splice(0, 0, ...removed);

    for (const languageTag of keys) {
      const language = lang.getLanguageByTag(languageTag);
      const displayName =
        language?.display(displayLanguage) || placeholderMissingLanguageTranslation;

      ret.push({
        code: languageTag,
        name: displayName,
        language: language,
      });
    }

    // Sort the languages by their display name, but keep the primary languages at the start.
    ret.sort((a, b) => {
      if(a.code.startsWith("de") || a.code.startsWith("en")) {
        return -1;
      }
      if(b.code.startsWith("de") || b.code.startsWith("en")) {
        return 1;
      }
      return a.name.localeCompare(b.name);
    });

    return ret;
  }

  add(language: Language): void {
    this.set(language.toString(), language);
  }

  /**
   * Tries to return a language that is close to the requested one. Result may be more general or more specific, 
   * or differ in any other way, but will have the same primary tag. May return null.
   *
   * @param {Language} requested
   * @return {*}  {Language}
   * @memberof LanguageCollection
   */
  getClosestMatch(requested?: Language): Language | null {
    if (!requested) {
      return null;
    }

    const reqFull = requested.toString();
    const reqPrimary = requested.getPrimary();

    // try exact match
    if (this.has(reqFull)) {
      return this.get(reqFull)!;
    }

    // try broader match
    for (const candidateLanguage of this.values()) {
      if (reqFull.startsWith(candidateLanguage.toString())) {
        return candidateLanguage;
      }
    }

    // try primary-only-match
    for (const candidateLanguage of this.values()) {
      if (candidateLanguage.getPrimary() == reqPrimary) {
        return candidateLanguage;
      }
    }

    // nothing matches
    return null;    
  }

  asArray(): Array<Language> {
    return Array.from(this.values());
  }

  toString(): string {
    return "LanguageCollection {" + this.asArray().join(", ") + "}";  
  }
}
