<template>
  <Button
    :icon="iconClass"
    :class="statusClass"
    @click="actionButtonClicked"
    :aria-label="statusLabel"
    :disabled="status == 'empty'"
    :label="label"
  />
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Button from "primevue/button";

interface URLPlayButtonData {
  status: string;
  nextUpdate: number;
  timerId: number;
}

export default defineComponent({
  name: "URLPlayButton",
  components: {
    Button,
  },
  data(): URLPlayButtonData {
    return {
      status: "unknown",
      nextUpdate: 0,
      timerId: 0,
    };
  },
  props: {
    url: String,
    label: String, // to be displayed on the button
    title: String, // optional. title of the sound, for playback metadata
  },
  created() {
    this.updateStatus(Math.random() * 300);
  },
  computed: {
    iconClass(): string {
      const player = this.globalProps.player;
      if(this.status == 'empty' || !this.url) {
        return "pi pi-ban"
      }
      if(this.status == 'waiting' || this.status == 'converting') {
        return "pi pi-spin pi-spinner";
      }
      if(player) {
        if(player.playing && player.currentEntry?.url == this.url) {
           return "pi pi-pause";
        } else {
          return "pi pi-play";
        }
      } 
      return "pi pi-question";
    },
    statusClass(): string {
      if (this.status == "unknown") {
        return "p-button-primary";
      }

      if (this.status == "waiting") {
        return "p-button-warning";
      }

      if (this.status == "converting") {
        return "p-button-secondary";
      }

      if (this.status == "ready") {
        return "p-button-info";
      }

      if (this.status == "failed") {
        return "p-button-danger";
      }

      if (this.status == "postponed") {
        return "p-button-primary";
      }
      return "p-button-primary";
    },
    statusLabel(): string {
      return this.$t("aria_button_play_status", { context: this.status });
    },
  },
  watch: {
    url() {
      this.updateStatus(500);
    },
  },
  methods: {
    actionButtonClicked() {
      if(!this.url) {
        return;
      }

      let player = this.globalProps.player;
      if(player) {

        if(player.currentEntry?.url == this.url) {
          if(player.playing) {
            player.pause();
          } else {
            player.resume();
          }
        } else {
          player.playUrl(this.url || "", false, undefined, this.title);
          this.updateStatus(10);
        }
      } else {
        console.warn("No Player defined.");
      }
    },
    async updateStatusNow() {
      if (!this.url) {
        this.status = "empty";
        return;
      }
      try {
        // First step: set this.status
        this.status = "unknown";
        const result = await fetch(this.url, { method: "HEAD" });
        var numericStatus = result.status;
        if (numericStatus >= 200 && numericStatus < 300) {
          this.status = result.headers.get("X-Tts-Status") || "ready";
          // before using HEAD, this was `await result.text()`
        }
        if (numericStatus >= 400 && numericStatus < 600) {
          this.status = "failed";
        }

        // Second step: re-schedule update, depending on this.status
        if (this.status == "ready" || this.status == "empty") {
          return;
        }
        if (this.status == "pending" || this.status == "waiting" || this.status == "converting") {
          this.updateStatus(750 + Math.random() * 300);
        } else {
          this.updateStatus(5000 + Math.random() * 300);
        }
      } catch (e) {
        this.status = "failed";
        this.updateStatus(15000);
      }
    },
    /**
     * Clears any present update timer, then schedules an update in the future.
     */
    updateStatus(ms: number) {
      if (this.timerId != 0) {
        window.clearTimeout(this.timerId);
        this.timerId = 0;
      }

      this.timerId = window.setTimeout(this.updateStatusNow, ms);
    },
  },
});
</script>
