<template>
  <Header />
  <EmailConfirmationMessage />
  <main>
    <router-view />
  </main>
  <Footer :playerVisible="playerVisible" />
  <ConfirmDialog :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '50vw' }" />
  <LoginDialog :showToasts="true" ref="theLoginDialog" />
  <div v-if="globalProps?.accountInfo?.isAdmin" id="cardPermissionHighlight">
    <label id="highlightPermissionChecksLabel" class="mr-2">{{ $t("checkbox_highlight_permission_checks") }}</label>
    <Checkbox aria-labelledby="highlightPermissionChecksLabel" v-model="globalProps.highlightPermissionChecks" :binary="true" />
  </div>
  <Toast position="bottom-center" group="bc" />

  <div v-show="playerVisible" class="bottomOverlay">
    <Player ref="thePlayer" :enableOpus2Caf="true" />
  </div>
</template>

<script lang="ts">
import { EmailConfirmationMessage } from "@/components";
import Footer from "@/views/Footer.vue";
import Header from "@/views/Header.vue";
import { lang } from "@tactonom/lang-js";
import { LoginDialog, PermissionCheck, Player } from "editor-base/components";
import { PlayerInterface, UserRole } from "editor-base/utils/OtherTypes";
import { templateToast } from "editor-base/utils/Tools";
import { type GlobalProps } from "lib/editor-base/utils/GlobalProps";
import Checkbox from "primevue/checkbox";
import ConfirmDialog from "primevue/confirmdialog";
import Toast from "primevue/toast";
import { defineComponent } from "vue";

interface AppData {
  interval: number | undefined;

  // The globalProps are accessible to any Vue component, but to make them visible in 
  // the Vue development tools while debugging, they have to be re-exported somewhere.
  globalPropsAccessor: GlobalProps | undefined;
  UserRole: typeof UserRole;
}

export default defineComponent({
  name: "App",
  components: {
    Header,
    Footer,
    EmailConfirmationMessage,
    PermissionCheck,
    Checkbox,
    ConfirmDialog,
    Toast,
    LoginDialog,
    Player,
  },
  data(): AppData {
    return {
      interval: undefined,
      globalPropsAccessor: undefined,
      UserRole,
    };
  },
  async mounted() {
    // TODO full remove addLinkListener
    //this.addLinkListener();
    await this.updateData();
    this.interval = window.setInterval(this.updateData, 30000);
    this.globalPropsAccessor = this.globalProps;
    window.globalPropsAccessor = this.globalProps;
    this.globalProps.triggerLogin = async (loginReason?: string) => {
      return this.$refs.theLoginDialog.show(loginReason);
    };
    this.globalProps.triggerLogout = async () => {
      // copied from ProfileRegister.vue
      // TODO unify login and logout, we already have a feature branch for that
      this.loading = true;
      const result = await fetch(import.meta.env.VITE_SVG_REPO_BASE_URL + "/ctrl/auth/page/logout", {
        method: "GET",
        credentials: "include",
      });
      this.loading = false;

      if (result.ok || result.redirected) {
        templateToast("logout", "success");
        this.globalProps.accountInfo.id = undefined;
        this.globalProps.accountInfo.isLoggedIn = false;
        this.globalProps.accountInfo.isAdmin = false;
        this.globalProps.accountInfo.isManager = false;
      } else {
        templateToast("logout", "error");
        // We don't know if we're still logged in, but this should trigger an update of the accountInfo anyway:
        this.globalProps.accountInfo.isLoggedIn = undefined;
      }
      // TODO if a document is loaded, its metadata must be reloaded, so that
      // the new user rights apply
    };

    this.globalProps.player = this.$refs.thePlayer as PlayerInterface;
  },
  unmounted() {
    if (this.interval) {
      window.clearInterval(this.interval);
    }
  },
  watch: {
    "globalProps.accountInfo.id": {
      async handler(newId: string | undefined, oldId: string | undefined) {
        // This code shall only be called if changing from one login directly to another.
        // Transitions between login and logout are handled by the other watcher.
        if (oldId && newId && (oldId != newId)) {
          let response = await fetch("/ctrl/auth/api/account-info/me");
          this.globalProps.accountInfo = await response.json();
        }
      }
    },
    "globalProps.accountInfo.isLoggedIn": {
      async handler(newIsLoggedIn: boolean, oldIsLoggedIn: boolean) {
        // comparing negated booleans is needed to ignore for example a change from null to false.
        if (!oldIsLoggedIn != !newIsLoggedIn) {
          let response = await fetch("/ctrl/auth/api/account-info/me");
          this.globalProps.accountInfo = await response.json();
        }
      }
    },
  },
  methods: {
    addLinkListener() {
      // taken from https://dennisreimann.de/articles/delegating-html-links-to-vue-router.html and adjusted for Typescript
      window.addEventListener('click', event => {
        // ensure we use the link, in case the click has been received by a subelement
        let { target } = event;
        while (target && target instanceof Element && target.tagName.toLowerCase() != 'a') target = target.parentNode;

        // handle only links
        if (target && target instanceof Element && target instanceof HTMLAnchorElement && target.href) {
          const url = new URL(target.href);
          const to = url.pathname;

          // don't handle external links
          if (target.matches("a[href*='://']")) {
            return;
          }

          // some sanity checks taken from vue-router:
          // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
          const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event;

          // don't handle with control keys
          if (metaKey || altKey || ctrlKey || shiftKey) return;

          // don't handle when preventDefault called
          if (defaultPrevented) return;

          // don't handle right clicks
          if (button !== undefined && button !== 0) return;

          // don't handle if `target="_blank"`
          if (target && target.getAttribute) {
            const linkTarget = target.getAttribute('target') || ""
            if (/\b_blank\b/i.test(linkTarget)) return;
          }

          // handle language switch
          if (url.searchParams.has("uilanguage")) {
            // here we completely ignore the rest of the URL, because we assume this comes from the link in the upper right, 
            // which has not been updated along with changes by vue router. No matter what this old URL says, we want to
            // stay on the same page, which is done with a simple reload.
            event.preventDefault();

            const currentLanguage = this.globalProps.uiLang;
            const newLang = lang.getLanguageByTag(url.searchParams.get("uilanguage") || undefined);
            console.log("User requested switch from ", currentLanguage, " to ", newLang);
            // this should fix #807 and indirectly #702. It's not the full solution though,
            const ignoreCurrentLanguage = true;
            if (ignoreCurrentLanguage || newLang != currentLanguage) {
              console.log("Handle language switch to " + newLang?.display(lang.getEnglish()) + ". window.location is " + window.location.toString());
              document.cookie = "uilanguage=" + (newLang?.toString() || "") + "; Path=/";
              window.localStorage.setItem("uilanguage", newLang?.toString() || "");

              if (this.globalProps.editor?.document?.changed) {
                // special case: if the graphic has unsaved changes, apply the language change without reload:
                // i18next.changeLanguage(newLang || undefined);

                // But it does not work yet, because the vue components do not know that they should re-render now.
                // So do a reload instead:
                window.location.reload();
              } else {
                window.location.reload();
              }
            }
            return;
          }

          if (window.location.pathname == to) {
            // this is a same-page link, might contain an anchor or not.
            // the script from dennisreimann.de would not handle those, but 
            // I think we should handle them, or we get full page reloads
            // i.e. when the user already is on the list und clicks on "Browse".
         
            if(url.hash) {
              console.log("Scroll to anchor via normal vue-router handling.");

              // We could also do this, but then we would also need to push a history state
              // manually, which will be handled by vue-router instead.
              // document.querySelector(url.hash)?.scrollIntoView({
              //   behavior: 'smooth'
              // });
              // event.preventDefault();
            } else {
              console.log("Navigation to same url, but no hash given. Aborting routing, letting native behavior happen.");
              return;
            }

            // TODO we do not handle query params (except `uilanguage`) here
            // TODO we need to handle them if a graphic show page should link directly to another such page,
            // like when navigating clones.
          }

          if (event.preventDefault) {
            const resolved = this.$router.resolve(to);
            if (resolved.matched.length && resolved.matched[0]!.name != "catchAll") {
              event.preventDefault();

              const query: Record<string, string> = {};
              url.searchParams.forEach((value, key) => { query[key] = value; });

              this.$router.push({ path: to, query, hash: url.hash });
            } else {
              console.log("Link to " + to + " does not resolve, doing nothing.");
            }
          }
        }
      });
    },
    async updateData() {
      try {
        await Promise.all([
          fetch("/ctrl/auth/api/account-info/me").then((response) => response.json().then((json) => { this.globalProps.accountInfo = json; } )),
          fetch("/ctrl/tag/api/list").then((response) => response.json().then((json) => { this.globalProps.tags = json; })),
        ]);
      } catch (e) {
        console.log("Error during load of account-info, tag list: ", e);
      }
    }
  },
  computed: {
    playerVisible(): boolean {
      return this.$route.meta.needsPlayer;
    },
  },
});
</script>
<style>
  :root {
    --dark-purple: #781C50;
    --purple: #89155E;
  }

  html, body {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    min-height: 100%;
  }

  main {
    max-width: 1110px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 30px;
    min-height: 80vh;
    padding-top: 20px;
  }

  main.fullwidth {
    max-width: 100%;

  }

  /* Has no effect in production environment, but simulates the Wordpress css when using `npm run dev`. */
  #app {
    max-width: 1110px;
  }

  /* Better display for error messages with very long words, e.g. containing identifiers from code. */
  .p-toast-message-text {
    word-break: break-word;
  }

  /* Prevents unnecessary word wraps in menus. */
  .p-menu {
    width: unset !important;
  }

  /* Override theme color #383b53 (dark grey) with #bb0000 (red) */
  .p-float-label>.p-invalid+label {
    color:#bb0000 !important
  }

  .p-autocomplete.p-invalid.p-component>.p-inputtext,
  .p-calendar.p-invalid.p-component>.p-inputtext,
  .p-cascadeselect.p-invalid.p-component,
  .p-checkbox.p-invalid>.p-checkbox-box,
  .p-chips.p-invalid.p-component>.p-inputtext,
  .p-dropdown.p-invalid.p-component,
  .p-inputnumber.p-invalid.p-component>.p-inputtext,
  .p-inputswitch.p-invalid .p-inputswitch-slider,
  .p-inputtext.p-invalid.p-component,
  .p-listbox.p-invalid,
  .p-multiselect.p-invalid.p-component,
  .p-password.p-invalid.p-component>.p-inputtext,
  .p-radiobutton.p-invalid>.p-radiobutton-box,
  .p-selectbutton.p-invalid>.p-button,
  .p-treeselect.p-invalid.p-component,
  .p-togglebutton.p-button.p-invalid>.p-button {
      border-color:#bb0000 !important
  }

  .validationError {
    color: #bb0000;
  }

  /* Fix scrolling issue https://gitea.inventivio.com/Reader/svg-repo/issues/573#issuecomment-5992 
   * for native scrolling (may happen if a URL with anchor hash is explicitly (re-)loaded.
   * Navigation via vue-router needs a separate fix.
   */
  #unlisted-results, .anchor {
    scroll-margin-top: 110px;
  }

  .needsAdmin {
   filter: drop-shadow(0px 0px 3px #a24673);
  }

  .needsManager {
   filter: drop-shadow(0px 0px 3px #f49f52) drop-shadow(1px 1px 0px #f49f52);
  }

  .needsLoggedIn {
   filter: drop-shadow(0px 0px 3px #46a273);
  }

  #cardPermissionHighlight {
    padding: 1em;
    display: block flex;
    position: fixed !important;
    bottom: -3em;
    transition: bottom 200ms, background-color 200ms, border-color 200ms;
    left: -2px;
    height: 60px;
    align-items: center;
    background: transparent;
    border: 1px solid transparent;
    z-index: 1500;
  }

  #cardPermissionHighlight:hover {
    bottom: -2px;
    background: white;
    border-color: black;
  }

  .bottomOverlay {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1200;
    border-top: 2px solid var(--purple);
  }
</style>