import { BrailleTranslationService, type BrailleTranslationServiceJson } from "./BrailleTranslationService";
import { Language, type LanguageJson } from "./Language";
import { LanguageCollection } from "./LanguageCollection";
import { Service, type ServiceJson } from "./Service";
import { SpeechService, type SpeechServiceJson } from "./SpeechService";
import type { Voice } from "./Voice";
import { servicesAndLanguages } from "./generated/servicesAndLanguages";

// Re-export the most common classes for easier consumption
export { Language } from "./Language";
export { LanguageCollection } from "./LanguageCollection";
export { Service } from "./Service";
export { SpeechService } from "./SpeechService";
export { BrailleTranslationService } from "./BrailleTranslationService";
export { BrailleTranslationTable } from "./BrailleTranslationTable";
export { Voice } from "./Voice";
export { servicesAndLanguages };

interface ServicesAndLanguagesJson {
  languages: Record<string, LanguageJson>;
  services: Record<string, ServiceJson>;
}

export class ServicesAndLanguagesRegistry {
  allServices: Map<string, Service>;
  allLanguages: LanguageCollection;

  constructor() {
    this.allServices = new Map<string, Service>();
    this.allLanguages = LanguageCollection.construct();
    // The following line, added in version 1.3.0, was suspected to cause "can't access lexical declaration Language before initialization" at runtime.
    // At the moment, it seems like it was caused by something else, but if it ever happens again, check if manual init (like in version 1.2.3) solves it.
    this.parseServicesAndLanguages(servicesAndLanguages);
  }

  parseServicesAndLanguages(json: ServicesAndLanguagesJson) {
    const newLanguages = LanguageCollection.construct();
    for (const [tag, languageJson] of Object.entries(json.languages)) {
      const language = Language.from(tag);
      newLanguages.set(languageJson.fullTag, language);
    }

    const newServices = new Map();
    for (const serviceJson of Object.values(json.services)) {
      let service;
      if (serviceJson.type == "SPEECH") {
        service = new SpeechService(
          serviceJson as SpeechServiceJson,
          newLanguages
        );
      } else if (serviceJson.type == "BRAILLE") {
        service = new BrailleTranslationService(
          serviceJson as BrailleTranslationServiceJson,
          newLanguages
        );
      } else {
        service = new Service(serviceJson, newLanguages);
      }
      for (const serviceName of service.names) {
        newServices.set(serviceName, service);
      }
    }

    this.allServices = newServices;
    this.allLanguages = newLanguages;
  }

  /**
   * Returns one of the already existing language instances, or `undefined` if the language does not exist. Only returns exact matches.
   * TODO do we need another method that accepts different letter cases and/or separators, like "de-at" or "en_US"? Or should this
   * method support this anyway?
   *
   * @export
   * @param {string} [tag] a language tag like "de" or "en-US". Case and separator must match exactly for this to succeed.
   * @return {*}  {(Language | undefined)}
   */
  getLanguageByTag(tag?: string): Language | undefined {
    if (!tag) {
      return undefined;
    }
    return this.allLanguages.get(tag);
  }

  getDefaultLanguage(): Language {
    return this.allLanguages.get("en")!;
  }

  // This is currently the same as getDefaultLanguage, but if we should ever have a more
  // complex logic for getDefaultLanguage, this is guaranteed to still return english.
  getEnglish(): Language {
    return this.allLanguages.get("en")!;
  }

  getLanguageOrDefault(tag?: string): Language {
    return this.getLanguageByTag(tag) || this.getDefaultLanguage();
  }

  getUiLanguages(): LanguageCollection {
    return this.allServices.get("svg-repo user interface")!.languages;
  }

  getGraphicLanguages(): LanguageCollection {
    return (
      this.allServices.get("svg-repo graphics")?.languages ||
      LanguageCollection.construct()
    );
  }

  /**
   * Returns one of the already existing service instances, or `undefined` if the service does not exist. Only returns exact matches.
   *
   * @export
   * @param {string} [name] A service name. If a service has multiple names, any name will match, but it has to match exactly.
   * @return {*}  {(Service | undefined)}
   */
  getServiceByName(name?: string): Service | undefined {
    if (!name) {
      return undefined;
    }
    return this.allServices.get(name);
  }

  getVoiceByName(qualifiedVoiceName?: string): Voice | undefined {
    if (!qualifiedVoiceName) {
      return undefined;
    }

    const parts = qualifiedVoiceName.split("/");
    if (parts.length != 2) {
      throw new Error(
        "Qualified voice name must consist of two parts, separated be a slash. Given qualifiedVoiceName was '" +
          qualifiedVoiceName +
          "'"
      );
    }

    const service: SpeechService = this.getServiceByName(
      parts[0]
    ) as SpeechService;
    if (service == null) {
      throw new Error(
        "SpeechService '" +
          parts[0] +
          "' does not exist. Given qualifiedVoiceName was '" +
          qualifiedVoiceName +
          "'"
      );
    }
    return service.getVoiceByName(parts[1]!);
  }

  getPrimaryLanguage(language: Language): Language {
    if (language.isPrimary()) {
      return language;
    }
    return this.getLanguageByTag(language.toString())!;
  }

  // TODO maybe make instance method of language
  // TODO maybe user Record<Language, string>, not sure if Objects as keys are a good idea in JS.
  findStringForLanguage(
    dict: Record<string, any>,
    language: Language
  ): string | null {
    if (dict && lang) {
      if (dict[language.toString()]) {
        return dict[language.toString()];
      }

      // if no direct match exists, try to match with primary tag
      if (dict[language.toString()]) {
        return dict[language.toString()];
      }

      // if still no match exists, try to match with even less strict compare function
      for (const key in dict) {
        if (key.startsWith(language.toString())) {
          return dict[key];
        }
      }
    }
    return null;
  }

  findStringForAnyLanguageKey(
    dict: Record<string, any> | undefined,
    languages: Array<Language>, // this is not a LanguageCollection, since we need an ordered list
    defaultValue: any
  ): string {
    if (dict) {
      for (const language of languages) {
        const value = this.findStringForLanguage(dict, language);
        if (value) {
          if (language == languages[0]) {
            return value;
          } else {
            return language.getPrimaryTag() + ": " + value;
          }
        }
      }
    }
    if (dict && Object.keys(dict).length) {
      const language = Object.keys(dict)[0] as string;
      return language + ": " + dict[language]; // just return the first value
    }
    return defaultValue;
  }
}

/** 
 * Function is only needed internally, since {@link lang} is initialized by calling 
 * this function.
 * The implementation makes sure that the same instance is returned, even if the package 
 * is imported via multiple different paths, by storing it in globalThis. Users
 * of this package should not access it directly via globalThis, because the 
 * implementation might change later and not use globalThis anymore.
 */
function getInstance(): ServicesAndLanguagesRegistry {
  if (!globalThis.langInstance) {
    globalThis.langInstance = new ServicesAndLanguagesRegistry();
  }
  return globalThis.langInstance;
}

/** Provides access to the singleton instance of ServicesAndLanguagesRegistry. */
let lang: ServicesAndLanguagesRegistry = getInstance();

export { lang };

declare global {
  var langInstance: ServicesAndLanguagesRegistry;
}