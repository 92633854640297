<template>
  <header>
    <div id="leftStripe">&nbsp;</div>
    <!-- problind -->
    <a 
      class="logoLink"
      id="problindLink"
      href="https://www.problind.org/"
      :aria-label="$t('title_problind_logo')"
      :title="$t('title_problind_logo')"
    >
      <div class="topRow">
        <img 
          id="problindLogo"
          height="50"
          width="130"
          :src="problindLogo"
          alt=""
        />
      </div>
      <div class="bottomRow">
        {{ headerPrefix + $t("tagline_problind") }}
      </div>
    </a>
    <nav :aria-label="$t('aria_header_nav')">
      <!-- Share (currently search, will link to the landing page later) -->
      <RouterLink
        class="logoLink"
        to="/app/list"
        :aria-label="$t('title_share_logo')"
        :title="$t('title_share_logo')"
      >
        <img
          id="shareLogo"
          height="40"
          :src="shareLogo"
          alt=""
        />
      </RouterLink>
      <ul>
        <!-- Other top level nav items -->
        <div class="linebreaker">
          <NavItem v-for="navItem of navItemsTop" :navItem="navItem" />
        </div>
        <div class="linebreaker">
          <NavItem v-for="navItem of navItemsBottom" :navItem="navItem" />
        </div>
      </ul>
    </nav>
  </header>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import problindLogo from "@/assets/problind.svg";
import shareLogo from "@/assets/share.svg";
import iconNavAccount from "@/assets/icon-nav-account.svg";
import { TranslationKey } from "@/lib/translations";
import { RouterLink } from "vue-router";
import { lang } from "@tactonom/lang-js";
import i18next from "i18next";
import NavItem, { type NavItemData } from "./NavItem.vue";

interface HeaderData {
  problindLogo: typeof problindLogo;
  shareLogo: typeof shareLogo;
}

export default defineComponent({
  name: "Header",
  components: {
    NavItem,
  },
  data(): HeaderData {
    return {
      problindLogo,
      shareLogo,
    };
  },
  computed: {
    navItemsTop(): Array<NavItemData> {
      let items: Array<NavItemData> = [];
      items.push(this.createNavItem("list"));
      items.push(this.createNavItem("create"));
      items.push(this.createNavItem("upload"));
      items.push(this.createNavItem("help", this.$t("problind_url_manual")));
      if (this.globalProps.accountInfo?.isAdmin || this.globalProps.accountInfo?.isManager) {
        let admin = this.createNavItem("admin");
        admin.iconClass = "pi-cog";
        items.push(admin);
      }
      return items;
    },
    navItemsBottom(): Array<NavItemData> {
      let items: Array<NavItemData> = [];
      if (this.globalProps.accountInfo?.isLoggedIn) {
        let myAccount = this.createNavItem("my_account", "/app/my");
        myAccount.iconUrl = iconNavAccount;
        myAccount.children.push(this.createNavItem("profile", "/app/my/profile"));
        myAccount.children.push(this.createNavItem("my_graphics", "/app/my/graphics?authorId=" + this.globalProps.accountInfo.id));
        myAccount.children.push(this.createNavItem("logout", () => this.globalProps.triggerLogout()));
        items.push(myAccount);
      } else {
        items.push(this.createNavItem("login", () => { this.globalProps.triggerLogin();  }));
      }
      items.push();
      items.push(this.createNavItem("donate", this.$t("problind_url_donate")));
      items.push(this.createNavItem("switch_language", () => this.switchLanguage()));

      return items;
    },
    headerPrefix(): string {
      if (import.meta.env.BASE_URL == "/") {
        return "";
      } else {
        let marker = import.meta.env.BASE_URL;
        marker = marker.replaceAll("/","").toUpperCase();
        return marker + " ";
      }
    },
  },
  methods: {
    createNavItem(itemName: string, toOrAction: string | undefined | (() => void)): NavItemData {
      let item: NavItemData = {
        key: itemName,
        label: this.$t(("navi_" + itemName) as TranslationKey),
        to: undefined,
        class: "navi_" + itemName,
        children: [],
        action: undefined,
        iconUrl: undefined,
        iconClass: undefined,
      };
      if (typeof toOrAction == "string") {
        item.to = toOrAction as string;
      } else if (typeof toOrAction == "function") {
        item.action = toOrAction as (() => void);
      } else {
        item.to = "/app/" + itemName;
      }
      return item;
    },
    switchLanguage(): void {
      const currentLanguage = this.globalProps.uiLang;
      const newLang = ((currentLanguage == lang.getEnglish()) ? lang.getLanguageByTag("de") : lang.getLanguageByTag("en")) || lang.getDefaultLanguage();
      console.log("User requested switch from ", currentLanguage.toString(), " to ", newLang.toString());
           
      document.cookie = "uilanguage=" + newLang.toString() + "; Path=/";
      window.localStorage.setItem("uilanguage", newLang.toString());
      i18next.changeLanguage(newLang.toString());
      this.globalProps.uiLang = newLang;
    },
  },
});
</script>
<style scoped>

header {
  display: flex;
  align-items: stretch;
  width: 100%;
}

#leftStripe {
  display: block;
  background-color: var(--purple);
  width: 26px;
}

nav {
  background-color: var(--purple);
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-left: 20px;
  padding-right: 20px;
}

nav>ul {
  list-style-type: none;
  display: flex;
  padding-left: 0;
  justify-content: space-between;
  flex-grow: 1;
}

.linebreaker {
  display: flex;
  gap: 18px;
  flex-grow: 1;
  align-items: center;
  height: 50px;
}

.spacer {
  flex-grow: 1;
  min-width: 10px;
}

#shareLogo {
  /* This allows us to use a black-on-white svg and display it in white.
  The usual approach would be a white (-on-white) svg which is hard to 
  edit and view on its own. */
  filter: contrast(0%) brightness(2);
  margin-left: 60px;
  margin-right: 60px;
}

#problindLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding:20px;
  gap: 5px;
}

@media (max-width: 1400px) {
  nav > ul {
    flex-direction: column;
  }

  #shareLogo {
    width: 90px;
    margin-left: 30px;
    margin-right: 30px;
  }

  #problindLink .bottomRow {
    width: 145px;
  }
}

@media (max-width: 730px) {
  #problindLink {
    position: absolute;
    top: 8px;
    background-color: white;
    padding: 10px;
  }

  .bottomRow {
    display: none;
  }

  #shareLogo {
    position: absolute;
    top: 87px;
    left: 35px;
    margin: 0;
  }

  nav>ul {
    margin-left: 130px;
  }
}

.logoLink img#shareLogo, .logoLink img#problindLogo  {
  border-bottom: 3px solid transparent;
  padding-bottom: 4px;
}

.logoLink:hover img#shareLogo, .logoLink:focus img#shareLogo {
  outline: none;
  border-bottom: 3px solid white;
}

.logoLink:hover img#problindLogo, .logoLink:focus img#problindLogo {
  outline: none;
  border-bottom: 3px solid black;
}

.logoLink:focus {
  outline: none;
}

.logoLink {
  text-decoration: none;
}

a.logoLink,
a.logoLink:visited {
  color: black;
}

#problindLink .topRow {
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 30pt;
  gap: 10px;
}

#problindLink .bottomRow {
  text-align: center;
}

#problindLink:focus .bottomRow, #problindLink:hover .bottomRow {
  text-decoration: underline 2px black;
}

</style>