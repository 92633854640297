import type { Language } from "./Language";
import type { LanguageCollection } from "./LanguageCollection";

export interface BrailleTranslationTableJson {
	filename: string;
	displayName: string;
	indexName: string;
	nativeName: string;
	computer: boolean;
	language: string;
  }
  
  export class BrailleTranslationTable {
	filename: string;
	displayName: string;
	indexName: string;
	nativeName: string;
	computer: boolean;
	language: Language;

	constructor(
	  json: BrailleTranslationTableJson,
	  allLanguages: LanguageCollection
	) {
		this.filename = json.filename;
		this.displayName = json.displayName;
		this.indexName = json.indexName;
		this.nativeName = json.nativeName;
		this.computer = json.computer;
	 	this.language = allLanguages.get(json.language)!;
	}
  }
  