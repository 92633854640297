import { LanguageCollection } from "./LanguageCollection";

export interface ServiceJson {
  canonicalName: string;
  names: Array<string>;
  languages: Array<string>;
  type: string;
}

export class Service {
  canonicalName: string;
  names: Array<string>;
  languages: LanguageCollection;
  type: string;

  constructor(json: ServiceJson, allLanguages: LanguageCollection) {
    this.canonicalName = json.canonicalName;
    this.names = json.names;
    this.languages = LanguageCollection.construct(
      json.languages.map((tag) => allLanguages.get(tag)!)
    );
    this.type = json.type;
  }
}
