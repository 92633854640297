<!-- This component checks if the user has the needed permission(s), then shows or hides its content.
If it is marked as "mandatory", an error message is shown. Otherwise, the content is hidden silently.

In case that multiple `needs...` props are given, all of them must be met.
-->
<!-- There seems to be no working way to inject a class into a slot, in the same way that
  it seems to be impossible to use v-bind="$attrs" with slots. Using a div instead of a
  template around the slot solves both problems, even though there might remain a
  useless diff in the DOM sometimes. -->
<!-- Meta-comment: the following <template> may not contain any comments as direct child. -->
<template>
  <div v-if="permissionGiven" v-bind="$attrs" :class="permissionClasses" :title="permissionTooltip">
    <slot></slot> 
  </div>
	<template v-if="mandatory" v-bind="$attrs">
    <Message v-if="permissionLoading" v-bind="$attrs" severity="info" :closable="false">
      {{ $t("message_permission_check_loading") }}
    </Message>

    <Message v-else-if="!permissionGiven" v-bind="$attrs" severity="warn" :closable="false">
      <h2>{{ permissionErrorTitle }}</h2>
      {{ permissionErrorMessage }}
    </Message>
	</template>
  <span v-else v-bind="$attrs" style="display: none !important;" >
    <!-- This empty, invisible span is needed to prevent a warning from vue, because $attrs must go somewhere. -->
  </span>
</template>
<script lang="ts">
import Message from "primevue/message";
import { defineComponent, type PropType } from "vue";
import { UserRole } from "../utils/OtherTypes";
import { camelCaseToSnakeCase, uniqueNonNullish } from "../utils/Tools";
import type { TranslationKey } from "translations";

export default defineComponent({
  name: "PermissionCheck",
  props: {
    /** A single roles that is needed. */
    needs: String as PropType<UserRole>,
    /** Multiple roles from which the user only needs to have one. */
    needsOneOf: Object as PropType<Array<UserRole>>,
    /** Multiple roles that the user has to have. */
    needsAllOf: Object as PropType<Array<UserRole>>,
    mandatory: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    Message,
  },
  methods: {
    translateRole(role: UserRole): string {
      // Note that `camelCaseToSnakeCase` will add a leading underscore because role
      // always starts with an uppercase letter.
      return this.$t(("role" + camelCaseToSnakeCase(role)) as TranslationKey);
    }
  },
  computed: {
    permissionGiven(): boolean {
      if (!this.needs && !this.needsAllOf && !this.needsOneOf) {
        // special case: no permissions needed, then permission is given implicitly
        return true;
      }

      let info = this.globalProps?.accountInfo;
      if (!info) {
        return false; // Something is needed, but we don't have the needed account info yet -> no permission
      }

      // Some permission(s) needed and we have the account info? -> check every condition...
      if (this.needs && !info.roles?.includes((this.needs as string).toLowerCase())) {
        return false;
      }

      if (this.needsAllOf && !this.needsAllOf.every(needed => info.roles?.includes(needed.toLowerCase()))) {
        return false;
      }

      if (this.needsOneOf && !this.needsOneOf.some(needed => info.roles?.includes(needed.toLowerCase()))) {
        return false;
      }
      
      return true;
    },
    permissionLoading(): boolean {
      if (!this.needs && !this.needsAllOf && !this.needsOneOf) {
        // special case: no permissions needed, then there's no need to wait for permissions loading
        return true;
      }

      return !this.globalProps?.accountInfo;
    },
    permissionClasses(): Array<string> {
      if(!this.globalProps?.highlightPermissionChecks) {
        return [];
      }
      const roles: Array<UserRole> = uniqueNonNullish([this.needs, ...(this.needsOneOf || []), ...(this.needsAllOf || [])]);
      return roles.map(role => "needs" + role);
    },
    permissionErrorTitle(): string {
      if (this.globalProps?.accountInfo?.isLoggedIn) {
        return this.$t("error_no_permission_page_logged_in");
      } else {
        return this.$t("error_no_permission_page_anon");
      }
    },
    permissionErrorMessage(): string {
      let message = "";

      if(this.needs) {
        const role = this.translateRole(this.needs);
        message += this.$t("message_permission_check_needs", { role });
      }
      if(this.needsOneOf?.length) {
        const roles = this.needsOneOf.map(this.translateRole).join(", ");
        message += this.$t("message_permission_check_needs_one_of", { roles } );
      }
      if(this.needsAllOf?.length) {
        const roles = this.needsAllOf.map(this.translateRole).join(", ");
        message += this.$t("message_permission_check_needs_all_of", { roles } );
      }
      return message;
    },
    permissionTooltip(): string | undefined {
      if(this.globalProps?.highlightPermissionChecks) {
        return this.permissionErrorMessage;
      }
      return undefined;
    }
  },
});
</script>