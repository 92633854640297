import { BrailleTranslationTable, type BrailleTranslationTableJson } from "./BrailleTranslationTable";
import type { Language } from "./Language";
import type { LanguageCollection } from "./LanguageCollection";
import { Service, type ServiceJson } from "./Service";

export interface BrailleTranslationServiceJson extends ServiceJson {
  translationTables: Record<string, BrailleTranslationTableJson>;
  defaultTranslationTables: Record<string, string>;
  defaultDisplayTables: Record<string, string>;
  displayTables: Array<string>;
  hyphenationDictionaries: Record<string, string>;
}

export class BrailleTranslationService extends Service {
  translationTables: Record<string, BrailleTranslationTable>;
  defaultTranslationTables: Record<string, BrailleTranslationTable>;
  defaultDisplayTables: Record<string, string>;
  displayTables: Array<string>;
  hyphenationDictionaries: Record<string, string>;


  constructor(json: BrailleTranslationServiceJson, allLanguages: LanguageCollection) {
    super(json, allLanguages);

    this.translationTables = {};
    this.defaultTranslationTables = {};
    this.defaultDisplayTables = {};
    this.displayTables = [];
    this.hyphenationDictionaries = {};

    for (const [filename, tableJson] of Object.entries(json.translationTables || {})) {
      this.translationTables[filename] = new BrailleTranslationTable(tableJson, allLanguages);
    }

    for (const [languageTag, filename] of Object.entries(json.defaultTranslationTables || {})) {
      this.defaultTranslationTables[languageTag] = this.translationTables[filename]!;
    }
    
    this.defaultDisplayTables = json.defaultDisplayTables;

    this.displayTables = json.displayTables;
    this.hyphenationDictionaries = json.hyphenationDictionaries;
  }

  getDefaultTranslationTableForLanguage(language: Language): BrailleTranslationTable | undefined {
    return this.defaultTranslationTables[language.toString()];
  }

  getDefaultDisplayTableForLanguage(language: Language): string | undefined {
    return this.defaultDisplayTables[language.toString()];
  }

  getDefaultTablesForLanguage(language: Language): string | undefined {
    return this.getDefaultDisplayTableForLanguage(language) + "," + this.getDefaultTranslationTableForLanguage(language)?.filename;
  }

  getTranslationTablesForLanguage(language: Language): Array<BrailleTranslationTable> {
    return Object.values(this.translationTables).filter(table => table.language == language);
  }

  getTranslationTableByFilename(filename: string) {
    return this.translationTables[filename];
  }

  hasDisplayTable(filename: string): boolean {
    return this.displayTables.includes(filename);
  }

  hasHyphenationDictionary(filename: string): boolean {
    return Object.keys(this.hyphenationDictionaries).includes(filename);
  }

  encodingForHyphenationDictionary(filename: string): string {
    return this.hyphenationDictionaries[filename]!;
  }
}
