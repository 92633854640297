
<template>
	<li 
    @keydown.esc.stop="$emit('collapse');"
    :class="navItem.class"
  >
    <button 
      v-if="expandable || navItem.action"
      class="linkLike"
      @click="clickAction"
      :aria-expanded="ariaExpanded"
      :aria-controls="expandable ? myUid : undefined"
      :aria-current="childActive"
      ref="toggleButton"
      @keydown.esc="collapse()"
      :aria-label="navItem.label"
    >
      <img 
        v-if="navItem.iconUrl"  
        :src="navItem.iconUrl"
        alt=""
      />
      <i 
        v-if="navItem.iconClass"
        :class="'pi ' + navItem.iconClass"
      />
      <span>{{ navItem.label }}</span>
      <svg 
        v-if="expandable"
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path fill="currentColor" d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
      </svg>
    </button>
    <template v-else> <!-- Link (external or internal) -->
      <a 
        v-if="isExternalLink"
        :href="navItem.to!" 
        :aria-label="navItem.label + $t('postfix_extern')"
      >
        <img 
        v-if="navItem.iconUrl"
        :src="navItem.iconUrl"
        alt=""
        />
        <i 
          v-if="navItem.iconClass"
          :class="'pi ' + navItem.iconClass"
        />
        <span>{{ navItem.label }}</span>
        <span
          class="pi pi-external-link"
        />
      </a>

      <RouterLink 
        v-else
        :to="navItem.to!" 
        :aria-label="navItem.label"
      >
        <img 
          v-if="navItem.iconUrl"
          :src="navItem.iconUrl"
          alt=""
        />
        <i 
          v-if="navItem.iconClass"
          :class="'pi ' + navItem.iconClass"
        />
        <span>{{ navItem.label }}</span>
      </RouterLink>
    </template>
    <!-- Nested items -->
    <ul :hidden="!this.expanded" :id="myUid" class="dropDown">
      <NavItem 
        v-for="childItem of navItem.children" 
        :navItem="childItem" 
        @collapse="collapse"  
      />
    </ul> 
  </li>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { RouterLink } from "vue-router";

// WARNING this is the interface of the prop, not of the whole component
export interface NavItemData {
  key: string;
  label: string;
  to: string | undefined;
  action: (() => void) | undefined;
  class: string | undefined;
  iconUrl: string | undefined;
  iconClass: string | undefined;
  children: Array<NavItemData>;
}

interface NavItemComponentData {
  expanded: boolean;
  routerListenerInstalled: boolean;
  abortControllers: Array<AbortController>;
}

export default defineComponent({
	name: "NavItem",
  props: {
    navItem: {
      type: Object as PropType<NavItemData>,
      required: true,
    },
  },
  emits: [
    "collapse"
  ],
	data(): NavItemComponentData {
    return {
      expanded: false,
      routerListenerInstalled: false,
      abortControllers: [],
    };
	},
  beforeUpdate(): void {
    // in `mounted()` we don't get the correct value for `this.expandable`
    if(this.expandable && !this.routerListenerInstalled) {
      // Vue router does not offer a way to directly react to an activated link, so
      // we need to use a global listener.
      this.$router.afterEach(() => {
        if (this.expanded) {
          this.collapse();
        }
      });
      this.routerListenerInstalled = true;
    }
  },
	computed: {
    expandable(): boolean {
      return this.navItem.children?.length;
    },
    ariaExpanded(): any {
      if (!this.expandable) {
        return undefined;
      } else {
        return this.expanded;
      }
    },
    childActive(): true | undefined {
      if (this.expandable && this.$route.matched.some(route => route.path == this.navItem.to)) {
        return true;
      } else {
        return undefined;
      }
    },
    isExternalLink(): boolean {
      return !this.expandable && this.navItem.to.startsWith("https://");
    },
    myUid(): string {
      // `this.uid` is defined by vue-unique-id, and in template expressions, `uid` works
      // at runtime, but throws a compile-time error. So we explicitly expose it as `myUid`.
      return this.uid;
    },
	},
	methods: {
    clickAction(): void {
      if (this.navItem.action) {
        this.navItem.action();
        
        // Collapses the parent item:
        this.$emit("collapse");
      } else if (this.expandable) {
        if (this.expanded) {
          this.collapse();
        } else {
          this.expand();
        }
      }
    },
    addEventListenerForCollapsing(eventName: string) {
      let abortController = new AbortController();
      window.addEventListener(
        eventName,
        (e: Event) => { 
          if (e.target && this.$el != e.target && !this.$el.contains(e.target)) { 
            this.collapse(true);
          }
        },
        { 
          capture: true,
          signal: abortController.signal,
        }
      );
      this.abortControllers.push(abortController);
    },
    expand(): void {
      this.expanded = true;
      this.addEventListenerForCollapsing('focus');
      this.addEventListenerForCollapsing('click');
    },
    collapse(doNotFocusToggle?: boolean): void {
      this.expanded = false;
      if (!doNotFocusToggle) {
        this.$refs.toggleButton.focus();
      }
      for (const abortController of this.abortControllers) {
        abortController.abort();
      }
      this.abortControllers = [];
    },
	},
});
</script>
<style scoped>

button.linkLike {
  all: unset;
  cursor: pointer;
}

li {
  display: block;
}

a, button.linkLike {
  color: white;
  text-decoration-line: none;
  border-bottom: 3px solid transparent;
  font-size: 14pt;
  padding: 5px 0 0 0;
  margin: 0 5px 0 5px;
  font-weight: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
}

li a img, li button img, li a i, li button i {
  padding: 5px;
  width: 30px;
  height: 30px;
}

a[aria-current="page"],
button[aria-current="true"]  {
  font-weight: 800;
}

a:hover, 
a:focus,
button.linkLike:hover, 
button.linkLike:focus {
  outline: none;
  border-bottom: 3px solid white;
}

a:hover img, a:focus img {
  border-bottom: 3px solid white;
}

.navi_donate {
  background-color: white;
  color: var(--dark-purple);
  border-radius: 8px;
  padding: 10px;
  font-weight: 400;
}

li.navi_donate a {
  color: var(--dark-purple);
}

li.navi_donate a:hover, 
li.navi_donate a:focus {
  border-bottom: 3px solid var(--dark-purple);
}

button[aria-expanded="true"] svg {
	transform: rotate(180deg);
}

nav>ul>li {
  position: relative;
}

ul.dropDown {
  position: absolute;
  margin-top: 15px;
  background-color: white;
  border: 2px solid var(--purple);
  padding: 10px;
  z-index: 1600;
}

ul.dropDown>li {
  white-space: nowrap;
}

ul.dropDown>li a, 
ul.dropDown>li button.linkLike {
  color: var(--dark-purple);
}

ul.dropDown>li a:hover, 
ul.dropDown>li a:focus,
ul.dropDown>li button.linkLike:hover, 
ul.dropDown>li button.linkLike:focus {
  outline: none;
  border-bottom: 3px solid var(--dark-purple);
}

span.pi {
  margin-left: 5px;
  margin-bottom: 3px;
}

@media (max-width: 1550px) {
  li.navi_admin span {
    background-color: aqua;
    display: none;
  }
}

</style>