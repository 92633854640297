import { LanguageCollection } from "./LanguageCollection";
import type { SpeechService } from "./SpeechService";

export interface VoiceJson {
  name: string;
  costMultiplier: number;
  languages: Array<string>;
}

export class Voice {
  name: string;
  costMultiplier: number;
  languages: LanguageCollection;
  speechService: SpeechService;

  constructor(
    json: VoiceJson,
    allLanguages: LanguageCollection,
    speechService: SpeechService
  ) {
    this.name = json.name;
    this.costMultiplier = json.costMultiplier;
    this.languages = LanguageCollection.construct(
      json.languages.map((tag) => allLanguages.get(tag)!)
    );
    this.speechService = speechService;
  }

  getQualifiedName(): string {
    return this.speechService.shortEngineName + "/" + this.name;
  }
}
