<template>
  <div v-if="needsConfirmation">
    <Message v-if="sent" severity="success">{{ $t("message_mail_confirmation_sent", { address } ) }}</Message>
    <Message v-else-if="error" severity="error">{{ $t("message_mail_confirmation_error", { address } ) }}</Message>
    <Message v-else severity="info">{{ $t("message_mail_confirmation_needed", { address } ) }}<!--<a href="#" @click="showDialog">{{ $t('button_mail_confirmation_info')}}</a>--></Message>
  </div>
  <Dialog :header="$t('header_mail_confirmation_intro')" v-model:visible="dialogVisible" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
    <p>{{ $t("label_mail_confirmation_intro", { address }) }}</p>
    <p>{{ $t("label_mail_confirmation_change", { "navi_personal_data": $t("navi_personal_data") }) }}</p>
    <template #footer>
      <!-- TODO use actual button-click and vue router once the target page is ported to vuejs -->
      <a :href="'import.meta.env.BASE_URL' + 'app/profile'" target="_blank" class="no-underline">
          <Button :disabled="sending" :label="$t('button_mail_change')" />
      </a>
      <Button :icon="buttonIcon" :disabled="sending" :label="$t('button_mail_confirmation_send', { address })" @click="sendMail" />
    </template>
  </Dialog>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Message from "primevue/message";
import Button from "primevue/button";
import Dialog from "primevue/dialog";

import { AccountStatus } from "editor-base/utils/OtherTypes";
import { sendConfirmationMail } from "editor-base/utils/Api";

export default defineComponent({
  name: "EmailConfirmationMessage",
  components: {
    Message,
    Button,
    Dialog,
  },
  data() {
    return {
      dialogVisible: false,
      sending: false,
      sent: false,
      error: false,
    };
  },
  computed: {
    needsConfirmation(): boolean {
      return this.globalProps?.accountInfo?.status == AccountStatus.NEEDS_CONFIRMATION;
    },
    address(): string {
      return this.globalProps?.accountInfo?.email || this.$t("label_unknown");
    },
    buttonIcon(): string {
      if(this.sending) {
        return "pi pi-spin pi-spinner";
      } else {
        return "";
      }
    },
  },
  methods: {
    showDialog(): void {
      this.dialogVisible = true;
    },
    hideDialog(): void {
      this.dialogVisible = false;
    },
    async sendMail(): Promise<void> {
      this.sending = true;
      const result = await sendConfirmationMail(this.globalProps.accountInfo!.id);
      if (result.success) {
        this.sending = false;
        this.sent = true;
        this.dialogVisible = false;
      } else {
        this.sending = false;
        this.sent = false;
        this.error = true;
        this.dialogVisible = false;
      }
    },
  }
});
</script>
<style>
.p-message-text a {
  font-weight: 700;
  text-decoration: underline;
}
</style>